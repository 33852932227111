import { render, staticRenderFns } from "./IvrPhoneDataView.vue?vue&type=template&id=659d727e&scoped=true&"
import script from "./IvrPhoneDataView.vue?vue&type=script&lang=js&"
export * from "./IvrPhoneDataView.vue?vue&type=script&lang=js&"
import style0 from "./IvrPhoneDataView.vue?vue&type=style&index=0&id=659d727e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "659d727e",
  null
  
)

export default component.exports