<template>
  <a-card>
    <div class="query-wrap">
      <a-form-model layout="inline" :model="queryParam" v-bind="{}" ref="queryForm">
        <a-form-model-item label="手机号" prop="phone">
          <a-input v-model="queryParam.phone" style="width: 160px" placeholder="请输入手机号" />
        </a-form-model-item>
        <a-form-model-item label="批次号" prop="batch">
          <a-input v-model="queryParam.batch" style="width: 160px" placeholder="请输入批次号" />
        </a-form-model-item>
        <a-form-model-item label="任务" prop="taskId">
          <a-select v-model="queryParam.taskId" show-search option-filter-prop="children" :filter-option="filterOption" style="width: 160px" placeholder="请选择">
            <a-select-option value="">全部</a-select-option>
            <a-select-option v-for="item in tasks" :key="item.value" :value="item.value">{{ item.label }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="外呼状态" prop="status">
          <a-select v-model="queryParam.status" style="width: 120px" placeholder="请选择">
            <a-select-option value="">全部</a-select-option>
            <a-select-option value="DING">呼叫中</a-select-option>
            <a-select-option value="DEFAULT">待呼叫</a-select-option>
            <a-select-option value="FINISH">已呼叫</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="接听状态" prop="callStatus">
          <a-select v-model="queryParam.callStatus" style="width: 120px" placeholder="请选择">
            <a-select-option value="">全部</a-select-option>
            <a-select-option value="已接通">已接通</a-select-option>
            <a-select-option value="未接听">未接听</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{}">
          <a-space>
            <a-button type="primary" @click="query">查询</a-button>
            <a-button type="default" @click="reset">清空</a-button>
            <a-button type="danger" icon="download" @click="download" :loading="downloading" v-if="$checkPermission('Action:DingPhoneData:Export')">导出</a-button>
          </a-space>
        </a-form-model-item>
      </a-form-model>
    </div>
    <s-table ref="table" size="default" :rowKey="record => record.id" :columns="columns" :data="loadData">
      <span slot="record" slot-scope="text, record">
        <a-button type="default" size="small" icon="play-square" @click="play(record.record)">播放</a-button>
      </span>
    </s-table>

    <a-modal title="播放音频" :visible="visible" :footer="null" :centered="true" :destroyOnClose="true" @cancel="() => (visible = false)">
      <div class="dia-con">
        <audio :src="audioUrl" controls loop autoplay></audio>
      </div>
    </a-modal>
  </a-card>
</template>

<script>
import { STable } from '@/components'

export default {
  components: {
    STable
  },
  data() {
    return {
      tasks: [],
      columns: [
        {
          title: '任务',
          dataIndex: 'task',
          customRender: text => text.name
        },
        {
          title: '批次号',
          dataIndex: 'batch',
          ellipsis: true
        },
        {
          title: '姓名',
          dataIndex: 'username',
          width: 100
        },
        {
          title: '号码',
          dataIndex: 'phone',
          width: 120
        },
        {
          title: '状态',
          dataIndex: 'status',
          width: 90,
          align: 'center',
          customRender: text => (text === 'DEFAULT' ? '待呼叫' : text === 'DING' ? '呼叫中' : '已呼叫')
        },
        {
          title: '呼叫时间',
          dataIndex: 'callTime',
          width: 160
        },
        {
          title: '外呼状态',
          dataIndex: 'callStatus',
          width: 90,
          align: 'center'
        },
        {
          title: '接听时间',
          dataIndex: 'answerTime',
          width: 160
        },
        {
          title: '挂机时间',
          dataIndex: 'hangupTime',
          width: 160
        },
        {
          title: '通话时长',
          dataIndex: 'talkTime',
          width: 90,
          align: 'center'
        },
        {
          title: '录音地址',
          dataIndex: 'record',
          width: 100,
          ellipsis: true,
          scopedSlots: { customRender: 'record' }
        },
        {
          title: '录入时间',
          dataIndex: 'entryTime',
          width: 160
        }
      ],
      format: 'YYYY-MM-DD HH:mm:ss',
      datetime: undefined,
      downloading: false,
      // 查询条件参数
      queryParam: {
        phone: undefined,
        batch: undefined,
        taskId: undefined,
        status: undefined,
        callStatus: undefined
      },
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const params = Object.assign(parameter, this.queryParam)

        return this.$http.get('/ding/phone/findAll', { params }).then(res => {
          return res.body
        })
      },
      visible: false,
      audioUrl: ''
    }
  },
  created() {
    this.$http.get('/ding/task/select').then(res => {
      this.tasks = res.body
    })
  },
  methods: {
    query() {
      this.$refs.table.refresh()
    },
    reset() {
      this.$refs.queryForm.resetFields()
      this.$refs.table.refresh()
    },
    download() {
      this.downloading = true
      this.$http.get('/ding/phone/exportData', { params: this.queryParam, responseType: 'blob', download: true, timeout: 0 }).finally(() => (this.downloading = false))
    },
    play(url) {
      this.audioUrl = url
      this.visible = true
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
  }
}
</script>

<style scoped>
.query-wrap {
  padding-bottom: 20px;
}
.dia-con {
  display: flex;
  justify-content: center;
}
</style>
